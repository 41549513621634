<template>
  <vue-final-modal
    class="modal-max-bets"
    :overlay-transition="{ mode: 'in-out', duration: 250 }"
    :content-transition="{ mode: 'in-out', duration: 250 }"
  >
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title" />

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('max-bets')" />
      </div>
    </div>

    <div class="scroll">
      <atomic-image class="img" :src="getContent(props.currentLocaleData, props.defaultLocaleData, 'image')" />

      <div class="title">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'title') }}
      </div>

      <p class="text">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'description') }}
      </p>

      <div class="modal-max-bets__info">
        <atomic-icon id="warning-circle" />

        <span class="modal-max-bets__info-label">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'maxBetLabel') }}:
        </span>

        <span class="modal-max-bets__info-value">
          {{ props.maxBet }}
        </span>
      </div>

      <div class="actions">
        <button-base type="primary" size="md" @click="closeModal('max-bets')">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'confirmButton') }}
        </button-base>

        <button-base type="outlined" size="xs" @click="handleCancel">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'cancelButton') }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIModalsContent } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<CIModalsContent['maxBet']>;
    defaultLocaleData: Maybe<CIModalsContent['maxBet']>;
    maxBet: string;
  }>();

  const { closeModal } = useModalStore();
  const { localizePath, getContent } = useProjectMethods();
  const router = useRouter();

  const handleCancel = async (): Promise<void> => {
    await router.push(localizePath('/profile/bonuses'));
    await closeModal('max-bets');
  };
</script>

<style src="~/assets/styles/components/modal/max-bets.scss" lang="scss" />
